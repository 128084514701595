import React, { useState, useEffect, useRef, useCallback} from "react";
import "../App.css";
import InsertChartOutlinedIcon from "@mui/icons-material/InsertChartOutlined";
import Button from "@mui/material/Button";
import { ReactComponent as LogoSVG } from "../assets/svg/logo6.svg";
import LinkIcon from "@mui/icons-material/Link";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
  Modal,
  Typography,
  ButtonGroup,
  InputBase,
  Autocomplete,
  TextField,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import { styled } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ReactGA from "react-ga4";
import { jwtDecode } from "jwt-decode";
import { debounce } from 'lodash';

const StyledTableCellBelow = styled(TableCell)(({ theme, columnType }) => ({
  fontSize: 14,
  height: 48,
  backgroundColor: "#FFFFFF",
  ...{
    id: {
      fontWeight: 700,
      padding: "0px 4px",
      paddingLeft: "15px",
      width: "5%",
    },
    article: {
      padding: "0px 4px",
      width: "25%",
    },
    source: {
      padding: "0px 4px",
      width: "16.66%",
    },
    issueby: {
      padding: "16px 0",
      width: "16.66%",
      textAlign: "left",
    },
    date: {
      padding: "16px 0",
      textAlign: "center",
      width: "16.66%",
    },
  }[columnType || "concept"],
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.common.black,
    fontFamily: "'Glory', sans-serif",
    fontWeight: "bold", // This is the corrected line
    fontSize: "18px",
    borderBottom: "none",
    boxShadow: "0px -1px 0px 0px #0000001A inset",
    padding: "10px",
  },
  [`&.${tableCellClasses.body}`]: {
    borderBottom: "none",
  },
}));
const StyledTableCellSkeleton = styled(StyledTableCellBelow)(({ theme }) => ({
  padding: 10,
  borderBottom: "none",
  boxShadow: "none",
  backgroundColor: "transparent",
  // Override styles for head and body cells, if necessary
  [`&.${tableCellClasses.head}`]: {
    padding: "0", // Remove padding for header cells
    boxShadow: "none", // Remove boxShadow for header cells
    // ... any other styles you wish to override or add
  },
  [`&.${tableCellClasses.body}`]: {
    // ... any styles specific to body cells for skeleton
  },
}));
const StyledTableRow = styled(TableRow)(({ theme, isSelected }) => ({
  backgroundColor: isSelected ? "#F5F5F580" : "transparent",
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
function StyledTableRowSkeletonRight() {
  return (
    <StyledTableRow>
      <StyledTableCellSkeleton>
        <div className="skeleton-cell small-skeleton"></div>
      </StyledTableCellSkeleton>
      <StyledTableCellSkeleton>
        <div className="skeleton-cell keyword-skeleton"></div>
      </StyledTableCellSkeleton>
      <StyledTableCellSkeleton>
        <div className="skeleton-cell small-skeleton"></div>
      </StyledTableCellSkeleton>
      <StyledTableCellSkeleton>
        <div className="skeleton-cell small-skeleton"></div>
      </StyledTableCellSkeleton>
      <StyledTableCellSkeleton>
        <div className="skeleton-cell small-skeleton"></div>
      </StyledTableCellSkeleton>
    </StyledTableRow>
  );
}
function Navbar({ openModal }) {
  return (
    <div className="navbar2" style={{ borderBottom: "1px solid lightgray" }}>
      <Link to="/">
        <LogoSVG />
      </Link>
      <div className="search-icon-wrapper" onClick={openModal}>
        <SearchIcon className="tech-search-icon" />
      </div>
    </div>
  );
}
function BelowTable({ articlesData, isLoading }) {
  const tableRef = useRef(null);
  return (
    <TableContainer
      ref={tableRef}
      component={Paper}
      elevation={3}
      style={{ maxHeight: "880px", overflowY: "auto", width: "100%" }}
    >
      <Table stickyHeader sx={{ width: "100%" }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCellBelow columnType="id">#</StyledTableCellBelow>
            <StyledTableCellBelow columnType="article">
              Article
            </StyledTableCellBelow>
            <StyledTableCellBelow columnType="source">
              Source
            </StyledTableCellBelow>
            <StyledTableCellBelow columnType="issueby">
              Issued by
            </StyledTableCellBelow>
            <StyledTableCellBelow columnType="date">
              Date of issue
            </StyledTableCellBelow>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading
            ? Array(10)
                .fill(0)
                .map((_, idx) => <StyledTableRowSkeletonRight key={idx} />)
            : articlesData.map((article, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCellBelow columnType="id">
                    {index + 1}
                  </StyledTableCellBelow>

                  <StyledTableCellBelow columnType="article">
                    <Tooltip title={article.title}>
                      <Link
                        to={article.pdf_url}
                        target="_blank"
                        rel="noopener noreferrer"
                        underline="always"
                        style={{
                          color: "#435452",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "block",
                          maxWidth: "500px",
                        }}
                        onClick={() => {
                          ReactGA.event({
                            category: "PDF",
                            action: "Article PDF Clicked",
                            label: article.title,
                          });
                        }}
                      >
                        <LinkIcon
                          style={{
                            verticalAlign: "middle",
                            marginRight: "5px",
                          }}
                        />
                        {article.title}
                      </Link>
                    </Tooltip>
                  </StyledTableCellBelow>

                  <StyledTableCellBelow columnType="source">
                    {article.source}
                  </StyledTableCellBelow>

                  <StyledTableCellBelow columnType="issueby">
                    {article.org?.length > 0 ? article.org.join(", ") : "N/A"}
                  </StyledTableCellBelow>

                  <StyledTableCellBelow columnType="date">
                    {new Date(article.published_date).toLocaleDateString(
                      "en-CA",
                      { year: "numeric", month: "numeric", day: "numeric" }
                    )}
                  </StyledTableCellBelow>
                </StyledTableRow>
              ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function TechNavigatorArticle() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const keyword = searchParams.get("keyword");
  const cluster = searchParams.get("cluster");
  const services = searchParams.get("services");
  const servicesList = services.split(",");
  const period = searchParams.get("period");
  const concept = searchParams.get("concept");
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [conceptInput, setConceptInput] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [selectedButton, setSelectedButton] = useState("TechScouting");
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(null);
  useEffect(() => {
    const token = localStorage.getItem("access_token");
    if (token) {
      const decodedToken = jwtDecode(token);
      const isExpired = decodedToken.exp * 1000 < new Date().getTime();
      if (isExpired) {
        navigate("/Login?reset=accountExpired");
      }
    }
  }, [navigate]);
  const fetchAutocompleteOptions = async (prefix) => {
    if (!prefix) return;
    setLoading(true);

    const accessToken = localStorage.getItem("access_token");
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_V2}/v1/autocomplete`,
        {
          headers,
          params: { prefix: prefix },
        }
      );
      setOptions(response.data.results);
    } catch (error) {
      console.error("Error fetching autocomplete options:", error);
    } finally {
      setLoading(false);
    }
  };
  const debouncedFetchAutocompleteOptions = useCallback(
    debounce((newInputValue) => fetchAutocompleteOptions(newInputValue), 300),
    []
  );
  const handleChange = (newInputValue) => {
    setConceptInput(newInputValue);
    debouncedFetchAutocompleteOptions(newInputValue);
  };
  // const handleOptionChange = (event, newValue) => {
  //   setValue(newValue);

  //   // If an option is selected, set the searchTerm based on Abbv or Phrase
  //   if (typeof newValue === "object" && newValue !== null) {
  //     setConceptInput(newValue.Abbv ? newValue.Abbv : newValue.Phrase);
  //   } else if (typeof newValue === "string") {
  //     // If a string is entered (free solo), use it directly
  //     setConceptInput(newValue);
  //   }
  // };
  const handleOptionChange = (event, newValue) => {
    setValue(newValue);
    
    // If an option is selected, set the searchTerm based on Abbv or Phrase
    let termToSearch = '';
    if (typeof newValue === 'object' && newValue !== null) {
      termToSearch = newValue.Abbv ? newValue.Abbv : newValue.Phrase;
      setConceptInput(newValue.Abbv ? newValue.Abbv : newValue.Phrase);
    } else if (typeof newValue === 'string') {
      // If a string is entered (free solo), use it directly
      termToSearch = newValue;
      setConceptInput(newValue);
    }
  
    if (termToSearch) {
      // Navigate to the appropriate page based on the selected button
      if (selectedButton === "TechScouting") {
        navigate(`/TechScoutingResult?keywords=${encodeURIComponent(termToSearch)}`);
      } else if (selectedButton === "TechReadiness") {
        navigate(`/TechReadiness?keyword=${encodeURIComponent(termToSearch)}`);
      }
    } else {
      setConceptInput('');
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      const category = selectedButton; // "TechScouting" or "TechReadiness"
      const event_name = "search_performed";

      if (selectedButton === "TechScouting") {
        navigate(
          `/TechScoutingResult?keywords=${encodeURIComponent(conceptInput)}`
        );
      } else if (selectedButton === "TechReadiness") {
        navigate(`/TechReadiness?keyword=${encodeURIComponent(conceptInput)}`);
      }
    }
  };
  const fetchKeywordsData = async (industry, period, cluster, keyword) => {
    try {
      const accessToken = localStorage.getItem("access_token");
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get(
        `${
          process.env.REACT_APP_API_URL_V2
        }/v1/technology/articles?industries=${encodeURIComponent(
          industry
        )}&keyword=${keyword}&cluster=${cluster}&period=${encodeURIComponent(
          period
        )}`,
        { headers: headers }
      );
      setIsLoading(false);
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 403) {
        localStorage.removeItem("access_token");
        alert("Please Login Again.");
        window.location.reload();
      } else {
        console.error("Error fetching keywords data:", error);
      }
      setIsLoading(false);
      return null;
    }
  };
  const [clusterData, setClusterData] = useState([]);
  useEffect(() => {
    (async () => {
      const data = await fetchKeywordsData(services, period, cluster, keyword); // Assuming "services" is industry here
      setClusterData(data.articles);
    })();
  }, [keyword, services, period, cluster]);

  const handleSearch_api = () => {
    navigate(`/TechReadiness?keyword=${keyword}`);
  };
  const handleSearch = () => {
    if (selectedButton === "TechScouting") {
      navigate(`/TechScoutingResult?keywords=${conceptInput}`);
    } else if (selectedButton === "TechReadiness") {
      navigate(`/TechReadiness?keyword=${conceptInput}`);
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };
  return (
    <div className="white-background">
      <Navbar openModal={() => setOpenModal(true)} />
      <Modal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
          setOptions([]);
        }}
        aria-labelledby="search-modal"
        aria-describedby="search-functionality"
        align="right"
        sx={{
          position: "absolute",
          top: "70px",
          right: "30px",
        }}
        BackdropProps={{ style: { backgroundColor: "transparent" } }}
      >
        <div
          style={{
            backgroundColor: "white",
            width: "420px",
            height: "220px",
            borderRadius: "8px",
            padding: "10px",
            outline: "none",
            textAlign: "center",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Typography
            variant="h6"
            id="search-modal"
            align="left"
            style={{
              paddingLeft: "13px",
              paddingBottom: "20px",
              fontFamily: "Glory",
              fontWeight: "700",
            }}
          >
            Search
          </Typography>
          <ButtonGroup
            variant="contained"
            color="primary"
            aria-label="text primary button group"
          >
            <Button
              onClick={() => setSelectedButton("TechScouting")}
              style={
                selectedButton === "TechScouting"
                  ? {
                      width: "180px",
                      height: "40px",
                      borderTopRightRadius: "4px",
                      borderBottomRightRadius: "0",
                      fontSize: "16px",
                      whiteSpace: "nowrap",
                      fontFamily: "Glory",
                      fontWeight: "bold",
                      lineHeight: "18px",
                      letterSpacing: "0em",
                      textAlign: "left",
                      background: "#3D766F",
                      textTransform: "none",
                    }
                  : {
                      width: "180px",
                      height: "40px",
                      borderTopRightRadius: "4px",
                      borderBottomRightRadius: "0",
                      fontSize: "16px",
                      whiteSpace: "nowrap",
                      fontFamily: "Glory",
                      fontWeight: "bold",
                      lineHeight: "18px",
                      letterSpacing: "0em",
                      textAlign: "left",
                      background: "#F5F5F5",
                      color: "#00000066",
                      textTransform: "none",
                    }
              }
            >
              Tech Scouting
            </Button>
            <Button
              onClick={() => setSelectedButton("TechReadiness")}
              style={
                selectedButton === "TechReadiness"
                  ? {
                      width: "220px",
                      height: "40px",
                      borderTopLeftRadius: "4px",
                      borderBottomLeftRadius: "0",
                      fontSize: "16px",
                      whiteSpace: "nowrap",
                      fontFamily: "Glory",
                      fontWeight: "bold",
                      lineHeight: "18px",
                      letterSpacing: "0em",
                      textAlign: "left",
                      background: "#3D766F",
                      textTransform: "none",
                    }
                  : {
                      width: "220px",
                      height: "40px",
                      borderTopLeftRadius: "4px",
                      borderBottomLeftRadius: "0",
                      fontSize: "16px",
                      whiteSpace: "nowrap",
                      fontFamily: "Glory",
                      fontWeight: "bold",
                      lineHeight: "18px",
                      letterSpacing: "0em",
                      textAlign: "left",
                      background: "#F5F5F5",
                      color: "#00000066",
                      textTransform: "none",
                    }
              }
            >
              Tech Readiness Assessment
            </Button>
          </ButtonGroup>

          <Autocomplete
            freeSolo
            options={options}
            getOptionLabel={(option) =>
              typeof option === "string"
                ? option
                : option.Abbv
                ? `${option.Abbv} - ${option.Phrase}`
                : option.Phrase
            } 
            onInputChange={(event, newInputValue) =>
              handleChange(newInputValue)
            }
            onChange={handleOptionChange}
            onKeyDown={handleKeyPress}
            loading={loading}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder={
                  selectedButton === "TechReadiness"
                    ? "Enter a specific technology to assess technology maturity"
                    : "Enter one or more fields of interest, separated by ,"
                }
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                  style: {
                    width: "400px",
                    borderRadius: "0 0 4px 4px",
                    padding: "5px 10px",
                    border: "1px solid #ccc",
                    fontSize: "14px",
                    fontFamily: "Glory",
                    marginLeft: "10px",
                  },
                }}
              />
            )}
          />

          <Button
            variant="contained"
            style={{
              width: "400px",
              height: "48px",
              marginTop: "30px",
              borderRadius: "4px",
              backgroundColor: "#435452",
              color: "white",
            }}
            onClick={handleSearch}
          >
            Search
          </Button>
        </div>
      </Modal>
      <div className="tech-group-3">
        <span style={{ display: "block" }}>
          <div
            style={{
              width: "4px",
              height: "18px",
              marginRight: "10px",
              background: "#3D766F",
              display: "inline-block", // To make it inline
            }}
          ></div>
        </span>
        <Tooltip title={services}>
          <span
            style={{
              color: "#435452",
              fontFamily: "Glory, sans-serif",
              fontSize: "18px",
              fontWeight: 500,
              cursor: "pointer",
            }}
          >
            {services.includes(",") ? (
              <>
                {servicesList[0].replace(/and /g, "& ")}
                <span style={{ paddingLeft: "10px" }}>... / {concept}</span>
              </>
            ) : (
              <>
                <span>
                  {services} / {concept}
                </span>
              </>
            )}
          </span>
        </Tooltip>
      </div>
      <div className="tech-group-4" style={{ gap: "10px" }}>
        Articles include{" "}
        <span style={{ color: "#3D766F" }}>
          {" "}
          {keyword}{" "}
          {clusterData?.length > 0 && (
            <span
              style={{
                marginLeft: "10px",
                fontFamily: "Glory",
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "18px",
                letterSpacing: "0.01em",
                textAlign: "left",
                color: "#00000066",
              }}
            >
              ({clusterData?.length})
            </span>
          )}
        </span>
      </div>
      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <div className="tech-group-small">
          <span style={{ color: "#435452" }}> More about this keyword</span>
        </div>

        <div
          onClick={handleSearch_api}
          style={{
            display: "flex",
            // alignItems: 'center', // Ensures items are centered vertically
            cursor: "pointer",
          }}
        >
          <InsertChartOutlinedIcon
            style={{
              marginTop: "20px",
              marginRight: "10px",
              verticalAlign: "bottom",
              color: "#3D766F",
            }}
          />
          <span
            style={{
              borderColor: "#435452",
              color: "#3D766F",
              marginTop: "22px",
              fontWeight: 500,
              // verticalAlign: 'middle' // This ensures that the text is aligned to the middle of the line-height
            }}
          >
            Tech readiness assessment
          </span>
          <Tooltip title="In-depth analysis for immediate assessment of technology maturity and investment risk">
            <InfoOutlinedIcon
              style={{
                marginTop: "20px",
                marginLeft: "10px",
                color: "#00000066", // Color for the info icon
              }}
            />
          </Tooltip>
        </div>
      </div>

      <div className="tableContainer">
        <div style={{ display: "flex", maxHeight: "480px" }}>
          <Paper style={{ flex: 1, marginBottom: "10px" }}>
            <BelowTable articlesData={clusterData} isLoading={isLoading} />
          </Paper>
        </div>
      </div>
      <div style={{ height: "100px", marginTop: "300px" }}></div>
    </div>
  );
}

export default TechNavigatorArticle;
